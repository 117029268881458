@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.scheduleDemoModal {
  :global {
    .modalDialog {
      max-width: 600px;
      min-width: 375px;
      border-radius: 5px;
    }

    .modalHeader {
      padding: 0;
    }

    .modalCloseBtn {
      fill: #fff;
    }

    .modalFooter {
      padding-bottom: 30px;
    }
  }
}

.modalHeader {
  text-align: center;
  height: 100px;

  position: relative;

  border-radius: 5px 5px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:global(.tenant-header-dealvdr) {
    background-image: $ColorVdrGradient;
  }

  &:global(.tenant-header-finsight) {
    background-image: $ColorFinGradient;
  }

  &:global(.tenant-header-dealroadshow) {
    background-image: $ColorDrsGradient;
  }

  &:global(.tenant-header-evercall) {
    background-image: $ColorEvcGradient;
  }

  &:global(.tenant-header-investorset) {
    background-image: $ColorInvGradient;
  }

  &:global(.tenant-header-verisend) {
    background-image: $ColorVrsGradient;
  }

  &:global(.tenant-header-allocate) {
    background-image: $ColorAlcGradient;
  }

  &:global(.tenant-header-capedge) {
    background-image: $ColorCeGradient;
  }

  &:global(.tenant-header-17g5) {
    background-image: $Color17g5Gradient;
  }

  &:global(.tenant-header-researchroom) {
    background-image: linear-gradient(70deg, #717fa4 34.15%, #909bb9 98.06%);
  }
}

.backgroundImage {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.logoImage {
  width: 114px;
  height: 20px;

  margin-bottom: 10px;
}

.tenantName {
  color: #fff;
  font-family: $fontFamily;
  line-height: 18px;
  font-size: 15px;
  margin: 0;
  padding-top: 5px;
}

.modalHeaderTitle {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #fff;

  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
}

.contentDescription {
  margin: 0 0 30px;
  text-align: center;
}

.submitButton {
  width: 100%;
  height: 56px !important;
}

.spinner {
  width: 16px;
  height: 16px;
  margin: 0 auto;

  border-color: rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
}
