@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.successContainer {
  width: 100%;
  text-align: center;
}

.title {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;

  @include mqMaxWidth($screenXs) {
    font-size: 13px;
    line-height: 18px;
  }
}

.mainTitle {
  font-weight: bold;
}

.subTitle {
  margin-bottom: 30px;
}

.appointment {
  margin: 20px 0;
  background: #f2fff2;
}

.appointmentContainer {
  padding: 50px;

  display: flex;
  justify-content: center;
}

.contactUs {
  font-size: 12px;
  line-height: 17px;

  letter-spacing: 0.01em;

  color: $secondary3Color;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;

  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.label {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: $secondary3Color;
  width: 100px;
}

.value {
  line-height: 20px;
  letter-spacing: 0.01em;

  color: $titleColor;
}

.bold {
  font-weight: bold;
}

.actionsWrp {
  display: flex;
  justify-content: center;

  padding: 20px 0 40px;

  span {
    font-weight: 500;
  }

  @include mqMaxWidth($screenXs) {
    flex-direction: column;

    padding: 0 0 30px;
  }
}

.copiedText {
  svg {
    fill: $secondaryColor;
  }
}
